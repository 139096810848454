<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">操作日志</el-breadcrumb-item>
      <el-breadcrumb-item>日志列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <div class="btn-list">
        <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu" style="font-size:12px"></i>
        </el-button>
      </div>
      <el-form
        ref="form"
        :model="goodsForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="goodsForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="goodsForm.create_time"
            type="daterange"
            align="right"
            :unlink-panels="true"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="日志类型" label-width="120px">
          <el-select v-model="goodsForm.type" placeholder="请选择">
            <el-option label="普通日志" value="1"></el-option>
            <el-option label="手动减库存" value="2"></el-option>
            <el-option label="盲盒售罄" value="3"></el-option>
            <el-option label="手动加库存" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="shop.name" label="门店名称"> </el-table-column>
          <el-table-column prop="operate" label="操作"> </el-table-column>
          <el-table-column prop="ip" label="IP"> </el-table-column>
          <el-table-column prop="created_at" label="创建时间"> </el-table-column>
        </el-table>
        <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
      </div>
  </div>
</template>

<script>
import { imgUrl, httpUrl } from "@/utils/http.js"
import {getLogRecord_api} from "@/api/goods.js"
import axios from "axios"
import local from "@/utils/local.js"
export default {
  data() {
    return {
      imgUrl:imgUrl,
      goodsForm: {
        shopName: "",
        type:null,
        create_time: "",
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      active:1
    }
  },
  methods: {
    //获取商品列表
    async getGoodsList(){
      let res=await getLogRecord_api({
        shop_name:this.goodsForm.shopName,
        type:this.goodsForm.type,
        start_time: this.goodsForm.create_time?this.goodsForm.create_time[0]:'',
        end_time: this.goodsForm.create_time?this.goodsForm.create_time[1]:'',
        page:this.currentPage,
        limit:this.pageSize
      })
      this.tableData=res.data.data.list
      this.total=res.data.data.total_count
    },
     //导出
    chainGoodExport() {
      const loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const IsV=`${httpUrl}api/v2/order/goodsLogExport?shop_name=${this.goodsForm.shopName}&type=${this.goodsForm.type}&start_time=${this.goodsForm.create_time[0]||''}&end_time=${this.goodsForm.create_time[1]||''}`
      axios
        .get(
          IsV,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!');
          loading.close()
        })
    },
    //重置
    emptySearch() {
      this.goodsForm = {}
      this.currentPage=1
      this.getGoodsList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getGoodsList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
      this.getGoodsList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
      this.getGoodsList()
    }
  },
  created(){
    this.getGoodsList()
  },
  destroyed(){
    local.remove("shopName1")
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.btn-list {
  display: flex;
  align-items: center;
}
</style>